<template>
  <p>
    <span class="text-caption font-weight-bold" v-text="$attrs.label" />
    <br />
    <span v-text="$attrs.value ? $attrs.value : 'No registrado'" />
    <span v-if="$attrs.subvalue">
      <br />
      <span class="text-caption" v-text="$attrs.subvalue" />
    </span>
  </p>
</template>

<script>
export default {};
</script>