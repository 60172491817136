<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="10">
          <h1 class="text-h6" v-text="page_title" />
        </v-col>
        <v-col cols="12" xs="12" md="2" class="text-right">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="secondary"
            size="small"
            :to="{
              name: page_route,
            }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12" md="6">
          <v-col cols="12" xs="12" md="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <ViewInput label="Nombre" :value="data.name" />
                <div>
                  {{ data.description }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'ARCHIVOS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <ImgView
                  :src="
                    data.photo_file
                      ? url_storage + data.photo_file
                      : require('@/assets/image.jpg')
                  "
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-col cols="12" xs="12" md="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'REGISTRO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <ViewInput
                  label="Creación"
                  :value="data.created_at_name"
                  :subvalue="data.created_at"
                />
                <ViewInput
                  label="U. actualización"
                  :value="data.updated_at_name"
                  :subvalue="data.updated_at"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="12" xs="6" md="6">
          <BtnCircle
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
        <v-col cols="12" xs="6" md="6" class="text-right">
          <BtnCircle
            tLabel="Actualizar fotografía"
            tPosition="top"
            icon="mdi-camera"
            color="warning"
            size="small"
            :loading="loading"
            :click="handleDialogUpdatePhoto"
          />
          <BtnCircle
            tLabel="Editar"
            tPosition="top"
            icon="mdi-pencil"
            color="info"
            size="small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- modal update photo -->
    <div>
      <v-dialog
        v-model="modal_update_photo"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar dark>
            <v-btn icon dark @click="handleDialogUpdatePhoto">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-text="'Actualizar fotografía'" />
            <v-spacer />
            <v-toolbar-items>
              <v-btn dark text @click="handleUpdatePhoto">Aceptar</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-form v-on:submit.prevent ref="form_photo_file" lazy-validation>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="6">
                    <FileInputImg
                      label="Fotografía"
                      :model.sync="values.photo_file"
                      :rules="rules.photo_file"
                      accept="image/jpeg"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
//import functions
import { show, destroy, storeUpdate } from "../../requests/pageRequest";
import { msgConfirm, msgAlert, URL_STORAGE } from "../../control";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import ViewInput from "../../components/ViewInput.vue";
import ImgView from "../../components/ImgView.vue";
import FileInputImg from "../../components/FileInputImg.vue";

export default {
  components: {
    BtnCircle,
    ViewInput,
    ImgView,
    FileInputImg,
  },
  data() {
    return {
      //route name and page title
      page_route: "color",
      page_title: "Color",
      //vars control
      id: this.$attrs.id,
      login_info: this.$store.getters.getLogin,
      loading: true,
      //Modals
      modal_update_photo: false,
      //data for api
      url_storage: URL_STORAGE,
      data: {},
      values: {
        photo_file: null,
      },
      //rules form data
      rules: {
        photo_file: [
          (v) => (v && v.size > 2e6 ? "El archivo debe ser menor a 2MB" : true),
        ],
      },
    };
  },
  methods: {
    showData() {
      show(this.page_route, this.login_info.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;
            //destroy action
            destroy(
              this.page_route,
              this.login_info.token,
              this.id,
              this.login_info.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.message);
            });
          }
        });
    },
    handleDialogUpdatePhoto() {
      if (!this.modal_update_photo) {
        this.values.photo_file = null;
      }

      this.modal_update_photo = !this.modal_update_photo;
    },
    handleUpdatePhoto() {
      //validate form data
      if (this.$refs.form_photo_file.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma la actualización de la fotografía?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              //store or update action
              storeUpdate(
                `${this.page_route}/updatephoto`,
                this.login_info.token,
                this.id,
                this.values,
                this.login_info.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.showData();
                } else {
                  console.log(response.message);
                }
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    this.showData();
  },
};
</script>